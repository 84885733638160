import { Modal, ModalFooter, Form } from "react-bootstrap";
import { useState } from "react";
import PropTypes from "prop-types";

const DigitainEditProviderModal = (props) => {
  const {
    onSaveProviderDetailsClickedCallback,
    onCancelClickedCallback,
    providerToEdit,
    ...modalProps
  } = props;
  const [providerDisplayName, setProviderDisplayName] = useState(() =>
    providerToEdit ? providerToEdit.providerDisplayName : ""
  );

  const onSaveProviderDetailsClicked = () => {
    let providerDetails = {
      providerId: providerToEdit.providerId,
      providerDisplayName,
    };
    onSaveProviderDetailsClickedCallback(providerDetails);
  };
  const onCancelClicked = () => {
    onCancelClickedCallback();
  };
  return (
    <Modal
      centered
      // backdrop="static"
      // keyboard={false}
      {...modalProps}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Provider</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="form-group mb-0">
          <Form.Label>Provider Display Name</Form.Label>
          <Form.Control
            type="text"
            value={providerDisplayName}
            onChange={(e) => setProviderDisplayName(e.target.value)}
            required
          />
        </Form.Group>
      </Modal.Body>
      <ModalFooter>
        <div className="actions">
          <button className="btn btn-danger" onClick={onCancelClicked}>
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={onSaveProviderDetailsClicked}
          >
            Save
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

DigitainEditProviderModal.propTypes = {
  providerToEdit: PropTypes.object,
  onSaveProviderDetailsClickedCallback: PropTypes.func,
  onCancelClickedCallback: PropTypes.func,
};

export { DigitainEditProviderModal };
