import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Login } from "../components/Login";
import { ForgotPassword } from "../components/ForgotPassword";
import { ForgotUsername } from "../components/ForgotUsername";
import { ResetPassword } from "../components/ResetPassword";
import { Dashboard } from "../components/pages/Dashboard";
import { SupportChat } from "../components/pages/Support_chat";

import { AgentManagement } from "../components/pages/Agent/Agent_list";
import { AgentManagementAdd } from "../components/pages/Agent/Agent_add";
import { AgentManagementView } from "../components/pages/Agent/Agent_view";

import { UserManagement } from "../components/pages/User/User_list";
import { UserManagementAdd } from "../components/pages/User/User_add";
import { UserManagementView } from "../components/pages/User/User_view";

import { BannerManagement } from "../components/pages/Banner/Banner_list";
import { BannerManagementAdd } from "../components/pages/Banner/Banner_add";

import { RoleManagement } from "../components/pages/Roles/Role_list";
import { RoleManagementAdd } from "../components/pages/Roles/Role_add";

import { PromocodeManagement } from "../components/pages/Promocode/Promocode_list";
import { PromocodeManagementAdd } from "../components/pages/Promocode/Promocode_add";
import { PromocodeView } from "../components/pages/Promocode/Promocode_view";

import { SubadminManagement } from "../components/pages/Subadmin/Subadmin_list";
import { SubadminManagementAdd } from "../components/pages/Subadmin/Subadmin_add";

import { Settings } from "../components/pages/Setting/Settings";
import { GameManagementAdd } from "../components/pages/Setting/Game_Management_add";
import { GameManagementView } from "../components/pages/Setting/Game_Management_view";

import { Vip_Membership_Add } from '../components/pages/Setting/Vip_Membership_Add'

import { RequestManagement } from "../components/pages/Request/Request_list";
import { Request_coin_add } from '../components/pages/Request/Request_coin_add'
import { Request_reward_coin_list } from "../components/pages/Request/Request_reward_coin_list";

import { Profile } from "../components/pages/Profile/Profile";
import { Rewards } from "../components/pages/Rewards";

import { ReportCommission } from "../components/pages/Report/Commission";
import { AllTransactionHistory } from "../components/pages/Report/AllTransactionHistory"
import { Bet_History } from "../components/pages/Report/Bet_History";
import { Bet_Report } from "../components/pages/Report/Bet_Report";
import { User_Bet_Report } from "../components/pages/Report/User_Bet_Report";
import { Earning_Report } from "../components/pages/Report/Earning_Report";
import { OpenBets } from "../components/pages/Report/OpenBets";

import { VIP_Membership_Report } from "../components/pages/Report/VIP_Membership_Report";

import { Contest } from "../components/pages/Contest/Contest_list";
import { ContestAdd } from "../components/pages/Contest/Contest_add";

import { Match } from "../components/pages/Match/Match_list";
import { MatchContest } from "../components/pages/Match/Match_contest";
import { MatchUsers } from "../components/pages/Match/Match_users";

import { Notification_List } from "../components/pages/Notification/Notification_List";
import { Notification_Add } from "../components/pages/Notification/Notification_Add";
import { Notification_View } from "../components/pages/Notification/Notification_View";

import { Spin_Wheel_List } from "../components/pages/Spin_Wheel/Spin_Wheel_List"
import { Spin_Wheel_Add } from "../components/pages/Spin_Wheel/Spin_Wheel_Add"
import { Spin_Wheel_User_Records } from "../components/pages/Spin_Wheel/Spin_Wheel_User_Records"

import { Leaderboard_List } from "../components/pages/Leaderboard/Leaderboard_List";

import { KycList } from "../components/pages/Kyc/KycList"

import { Error404 } from "../components/pages/Error404";
import PrivateRoute from "./PrivateRoute";

import { DigitainGameSettings } from "../components/pages/Digitain/DigitainGameSettings";
import DigitainCategorySort  from "../components/pages/Digitain/DigitainCategorySort/DigitainCategorySort.component"
import DigitainGameSort from '../components/pages/Digitain/DigitainGameSort/DigitainGameSort.component'
import DigitainGameView from "../components/pages/Digitain/DigitainGameView/DigitainGameView.component";

function DefaultRoutes() {
  return (
    <section className="page">
      <Router forceRefresh>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/forgot-username" element={<ForgotUsername />} />
          <Route exact path="/reset-password/:link" element={<ResetPassword />} />

          <Route exact path="/dashboard" element={<PrivateRoute Component={Dashboard} />} />
          <Route exact path="/support-chat" element={<PrivateRoute Component={SupportChat} />} />

          <Route exact path="/user-management" element={<PrivateRoute Component={UserManagement} />} />
          <Route exact path="/user-management/view/:id" element={<PrivateRoute Component={UserManagementView} />} />
          <Route exact path="/user-management/add" element={<PrivateRoute Component={UserManagementAdd} />} />
          <Route exact path="/user-management/edit/:id" element={<PrivateRoute Component={UserManagementAdd} />} />

          <Route exact path="/request-management" element={<PrivateRoute Component={RequestManagement} />} />
          <Route exact path="/request-add-coin" element={<PrivateRoute Component={Request_coin_add} />} />
          <Route exact path="/request-reward-coin" element={<PrivateRoute Component={Request_reward_coin_list} />} />

          <Route exact path="/banner-management" element={<PrivateRoute Component={BannerManagement} />} />
          <Route exact path="/banner-management/add" element={<PrivateRoute Component={BannerManagementAdd} />} />
          <Route exact path="/banner-management/edit/:id" element={<PrivateRoute Component={BannerManagementAdd} />} />

          <Route exact path="/agent-management" element={<PrivateRoute Component={AgentManagement} />} />
          <Route exact path="/agent-management/add" element={<PrivateRoute Component={AgentManagementAdd} />} />
          <Route exact path="/agent-management/edit/:id" element={<PrivateRoute Component={AgentManagementAdd} />} />
          <Route exact path="/agent-management/view/:id" element={<PrivateRoute Component={AgentManagementView} />} />

          <Route exact path="/role-management" element={<PrivateRoute Component={RoleManagement} />} />
          <Route exact path="/role-management/edit/:id" element={<PrivateRoute Component={RoleManagementAdd} />} />

          <Route exact path="/promo-code" element={<PrivateRoute Component={PromocodeManagement} />} />
          <Route exact path="/promo-code/add" element={<PrivateRoute Component={PromocodeManagementAdd} />} />
          <Route exact path="/promo-code/edit/:id" element={<PrivateRoute Component={PromocodeManagementAdd} />} />
          <Route exact path="/promo-code/view/:id" element={<PrivateRoute Component={PromocodeView} />} />

          <Route exact path="/subadmin-management" element={<PrivateRoute Component={SubadminManagement} />} />
          <Route exact path="/subadmin-management/add/:id" element={<PrivateRoute Component={SubadminManagementAdd} />} />
          <Route exact path="/subadmin-management/edit/:id" element={<PrivateRoute Component={SubadminManagementAdd} />} />

          <Route exact path="/settings" element={<PrivateRoute Component={Settings} />} />
          <Route exact path="/settings/game/add" element={<PrivateRoute Component={GameManagementAdd} />} />
          <Route exact path="/settings/game/edit/:id" element={<PrivateRoute Component={GameManagementAdd} />} />
          <Route exact path="/settings/game/view/:id" element={<PrivateRoute Component={GameManagementView} />} />
          <Route exact path="/settings/vip/add" element={<PrivateRoute Component={Vip_Membership_Add} />} />
          <Route exact path="/settings/vip/add/:id" element={<PrivateRoute Component={Vip_Membership_Add} />} />

          <Route exact path="/digitain-game-settings" element={<PrivateRoute Component={DigitainGameSettings} />} />
          <Route exact path="/digitain/category-sort" element={<PrivateRoute Component={DigitainCategorySort}/>}/>
          <Route exact path="/digitain/game-sort/:id" element={<PrivateRoute Component={DigitainGameSort} />}/>
          <Route exact path="/digitain/game-view/:id" element={<PrivateRoute Component={DigitainGameView} />}/>
          
          

          <Route exact path="/rewards-management" element={<PrivateRoute Component={Rewards} />} />

          <Route exact path="/revenue-report" element={<PrivateRoute Component={ReportCommission} />} />
          <Route exact path="/all-transaction-history" element={<PrivateRoute Component={AllTransactionHistory} />} />
          <Route exact path="/report/vip_membership" element={<PrivateRoute Component={VIP_Membership_Report} />} />
          <Route exact path="/report/bet_history" element={<PrivateRoute Component={Bet_History} />} />
          <Route exact path="/report/bet_report" element={<PrivateRoute Component={Bet_Report} />} />
          <Route exact path="/report/user_bet_report/:id" element={<PrivateRoute Component={User_Bet_Report} />} />
          <Route exact path="/report/earnings_report" element={<PrivateRoute Component={Earning_Report} />} />
          <Route exact path="/report/open_bets" element={<PrivateRoute Component={OpenBets} />} />


          <Route exact path="/contest" element={<PrivateRoute Component={Contest} />} />
          <Route exact path="/contest/add" element={<PrivateRoute Component={ContestAdd} />} />
          <Route exact path="/contest/edit/:id" element={<PrivateRoute Component={ContestAdd} />} />

          <Route exact path="/match" element={<PrivateRoute Component={Match} />} />
          <Route exact path="/match/contest/:id" element={<PrivateRoute Component={MatchContest} />} />
          <Route exact path="/match/users/:id" element={<PrivateRoute Component={MatchUsers} />} />

          <Route exact path="/notification" element={<PrivateRoute Component={Notification_List} />} />
          <Route exact path="/notification/add" element={<PrivateRoute Component={Notification_Add} />} />
          <Route exact path="/notification/view/:id" element={<PrivateRoute Component={Notification_View} />} />
          <Route exact path="/notification/edit-and-send/:id" element={<PrivateRoute Component={Notification_Add} />} />

          <Route exact path="/spin-wheel" element={<PrivateRoute Component={Spin_Wheel_List} />} />
          <Route exact path="/spin-wheel/edit/:id" element={<PrivateRoute Component={Spin_Wheel_Add} />} />
          <Route exact path="/spin-wheel/user-records" element={<PrivateRoute Component={Spin_Wheel_User_Records} />} />

          <Route exact path="/leaderboard/list" element={<PrivateRoute Component={Leaderboard_List} />} />

          <Route exact path="/kyc" element={<PrivateRoute Component={KycList} />} />

          <Route exact path="/profile" element={<PrivateRoute Component={Profile} />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </Router>
    </section>
  );
}

export default DefaultRoutes;
