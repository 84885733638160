/* eslint-disable no-unused-vars */
/* eslint-disable no-control-regex */
import '../../styles/Sidebar.scss'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { isPermission } from '../../redux/selectors/authSelector';
import { CheckPermission } from '../../utils/Helper';
import Link from '../common/Link';

const Sidebar = () => {
  const navigate = useNavigate();
  const permission = useSelector(isPermission);
  const [open, setOpen] = useState('');

  const setOpencall = (e) => {
    if (open == e) { setOpen(''); } else {
      setOpen(e);
    }
  }

  useEffect(() => {
    const currentPage = window.location.href.split('#')[0];
    const allA = document.getElementsByTagName('A');
    for (let i = 0, len = allA.length; i < len; i++) {
      if (allA[i].href == currentPage) {
        allA[i].classList.add('active');
      } else {
        allA[i].classList.remove('active');
      }
    }
    if (!CheckPermission(permission)) navigate("/dashboard")

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [window.location.href])

  return (
    <>
      <div className='sidebar'>
        <ul>

          <li> <Link to="/dashboard"> <i className="mdi mdi-view-dashboard"></i> <span>Dashboard</span> </Link> </li>

          <li> <Link to="/user-management"> <i className="fas fa-users"></i> <span>User Management</span> </Link> </li>
          
          {CheckPermission(permission, '/report') &&
            <li onClick={() => setOpencall('report')} className={open == 'report' ? `active` : ``}>
              <a className="has-arrow">
                <i className="fas fa-layer-group"></i>
                <span>Reports</span>
              </a>
              <ul className="sub-menu" aria-expanded="false">
                {/* <li><a href="#">Country & State Income</a></li> */}
                <li><Link onClick={e => e.stopPropagation()} to="/report/open_bets">SportsBook Open Bets</Link></li>
                <li><Link onClick={e => e.stopPropagation()} to="/report/bet_history">Bet History</Link></li>
                <li><Link onClick={e => e.stopPropagation()} to="/report/bet_report">Bet Report</Link></li>
                <li><Link onClick={e => e.stopPropagation()} to="/report/earnings_report">Earnings Report</Link></li>
                <li><Link onClick={e => e.stopPropagation()} to="/revenue-report">Revenue Report</Link></li>
                <li><Link onClick={e => e.stopPropagation()} to="/all-transaction-history">Transaction History</Link></li>
                <li><Link onClick={e => e.stopPropagation()} to="/report/vip_membership">VIP Membership</Link></li>
              </ul>
            </li>
          }

          <li> <Link to="/rewards-management"> <i className="fab fa-sketch"></i> <span>Rewards Management</span> </Link> </li>

          {CheckPermission(permission, '/spin-wheel') &&
            <li onClick={() => setOpencall('spin-wheel')} className={open == 'spin-wheel' ? `active` : ``}>
              <a className="has-arrow">
                <i className="fas fa-layer-group"></i>
                <span>Spin Wheel Management</span>
              </a>
              <ul className="sub-menu" aria-expanded="false">
                <li><Link onClick={e => e.stopPropagation()} to="/spin-wheel">Spin Wheel Rewards</Link></li>
                <li><Link onClick={e => e.stopPropagation()} to="/spin-wheel/user-records">Spin Wheel User Records</Link></li>
              </ul>
            </li>
          }

          <li> <Link to="/match"> <i className="fas fa-american-sign-language-interpreting "></i> <span>Match Management</span> </Link> </li>

          <li> <Link to="/contest"> <i className="fab fa-modx "></i> <span>Default Contest</span> </Link> </li>

          <li> <Link to="/leaderboard/list"> <i className="fas fa-cog"></i> <span>Leaderboard</span> </Link> </li>

          <li> <Link to="/banner-management"> <i className="fas fa-user-edit"></i> <span>Banner Management</span> </Link> </li>
          
          {CheckPermission(permission, '/request-management') &&
            <li onClick={() => setOpencall('request-management')} className={open == 'request-management' ? `active` : ``}>
              <a className="has-arrow">
                <i className="fas fa-book"></i>
                <span>Request Management</span>
              </a>
              <ul className="sub-menu" aria-expanded="false">
                <li><Link onClick={e => e.stopPropagation()} to="/request-add-coin">Coin add request</Link></li>
                <li><Link onClick={e => e.stopPropagation()} to="/request-management">Withdrawal request ( iGame ) </Link></li>
                <li><Link onClick={e => e.stopPropagation()} to="/request-reward-coin">Withdrawal request ( Reward ) </Link></li>
              </ul>
            </li>
          }

          <li> <Link to="/kyc"> <i className="fas fa-user-edit"></i> <span>KYC Management</span> </Link> </li>

          <li> <Link to="/promo-code"> <i className="mdi mdi-school"></i> <span>Promocode Management</span> </Link> </li>

          <li> <Link to="/subadmin-management"> <i className="fas fa-users-cog"></i> <span>Subadmin Management</span> </Link> </li>

          <li> <Link to="/settings"> <i className="fas fa-cog"></i> <span>Settings</span> </Link> </li>

          <li> <Link to="/digitain-game-settings"> <i className="fas fa-cog"></i> <span>Digitain Game Settings</span> </Link> </li>

          <li> <Link to="/notification"> <i className="fas fa-comment"></i> <span>Notifications</span> </Link> </li>

        </ul>
      </div>
    </>
  )
}

export { Sidebar }